<template>
    <section class="o-account">
        <app-account-nav/>

        <transition-group class="o-account__body" name="fade" tag="div">

            <template v-if="!account.status.deleted">
                <template v-if="account.status.loading || loading">
                    Bezig met ophalen van profiel...
                </template>

                <template v-else>
                    <app-intro key="account-intro">
                        <h1>Mijn gegevens</h1>
                        <h3>Welkom, {{ account.user.firstname }}</h3>

                        <ul class="o-account__nav">
                            <li class="o-account__nav-item">
                                <a href="#0" v-confirm="{ ok: deleteAccount, message: 'Weet u zeker dat u uw account wil verwijderen?', okText: 'Verwijder', }">
                                    <span v-if="account.status.deleting">Bezig met verwijderen...</span>
                                    <span v-if="!account.status.deleting">Verwijder account</span>
                                </a>
                            </li>
                        </ul>
                    </app-intro>

                    <tabs key="account-tabs" :onSelect="resetTab">
                        <tab title="Account">
                            <form v-on:submit.prevent="updateDetails" class="form" novalidate>
                                <div class="form-fieldset">

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.firstname.$error, 'has-success': submittedDetails && !$v.formDetails.firstname.$error }">
                                        <label class="form-label" for="account-firstname">Voornaam</label>
                                        <input type="text" v-model="formDetails.firstname" id="account-firstname" name="firstname" class="form-control form-control--light" placeholder="Vul hier uw voornaam in" />
                                        <div v-if="submittedDetails && $v.formDetails.firstname.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.firstname.required">Voornaam is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.lastname.$error, 'has-success': submittedDetails && !$v.formDetails.lastname.$error }">
                                        <label class="form-label" for="account-lastname">Achternaam</label>
                                        <input type="text" v-model.lazy="formDetails.lastname" id="account-lastname" name="lastname" class="form-control form-control--light" placeholder="Vul hier uw achternaam in" />
                                        <div v-if="submittedDetails && $v.formDetails.lastname.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.lastname.required">Achternaam is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.company.$error, 'has-success': submittedDetails && !$v.formDetails.company.$error }">
                                        <label class="form-label" for="account-company">Bedrijfsnaam</label>
                                        <input type="text" v-model="formDetails.company" id="account-company" name="company" class="form-control form-control--light" placeholder="Vul hier uw bedrijfsnaam in" />
                                        <div v-if="submittedDetails && $v.formDetails.company.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.company.required">Bedrijfsnaam is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.address.$error, 'has-success': submittedDetails && !$v.formDetails.address.$error }">
                                        <label class="form-label" for="account-address">Adres</label>
                                        <input type="text" v-model.lazy="formDetails.address" id="account-address" name="address" class="form-control form-control--light" placeholder="Vul hier uw adres in" />
                                        <div v-if="submittedDetails && $v.formDetails.address.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.address.required">Adres is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.postcode.$error, 'has-success': submittedDetails && !$v.formDetails.postcode.$error }">
                                        <label class="form-label" for="account-postcode">Postcode</label>
                                        <input type="text" v-model.lazy="formDetails.postcode" id="account-postcode" name="postcode" class="form-control form-control--light" placeholder="Vul hier uw postcode in" />
                                        <div v-if="submittedDetails && $v.formDetails.postcode.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.postcode.required">Postcode is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.city.$error, 'has-success': submittedDetails && !$v.formDetails.city.$error }">
                                        <label class="form-label" for="account-city">Woonplaats</label>
                                        <input type="text" v-model.lazy="formDetails.city" id="account-city" name="city" class="form-control form-control--light" placeholder="Vul hier uw woonplaats in" />
                                        <div v-if="submittedDetails && $v.formDetails.city.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.city.required">Woonplaats is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.county.$error, 'has-success': submittedDetails && !$v.formDetails.county.$error }">
                                        <label class="form-label" for="register-county">Provincie</label>
                                        <select v-model="formDetails.county" id="register-county" name="county" class="form-control">
                                          <option disabled selected>Kies uw provincie</option>
                                          <option value="Groningen">Groningen</option>
                                          <option value="Friesland">Friesland</option>
                                          <option value="Noord Holland">Noord Holland</option>
                                          <option value="Zuid Holland">Zuid Holland</option>
                                          <option value="Zeeland">Zeeland</option>
                                          <option value="Drenthe">Drenthe</option>
                                          <option value="Noord brabant">Noord brabant</option>
                                          <option value="Gelderland">Gelderland</option>
                                          <option value="Overijssel">Overijssel</option>
                                          <option value="Utrecht">Utrecht</option>
                                          <option value="Flevoland">Flevoland</option>
                                          <option value="Limburg">Limburg</option>
                                        </select>
                                        <div v-if="submittedDetails && $v.form.county.$error" class="form-validation">
                                          <span v-if="!$v.formDetails.county.required">Provincie is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.phone.$error, 'has-success': submittedDetails && !$v.formDetails.phone.$error }">
                                        <label class="form-label" for="account-phone">Telefoonnummer</label>
                                        <input type="text" v-model.lazy="formDetails.phone" id="account-phone" name="phone" class="form-control form-control--light" placeholder="Vul hier uw telefoon nummer in" />
                                        <div v-if="submittedDetails && $v.formDetails.phone.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.phone.required">Telefoonnummer is verplicht</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedDetails && $v.formDetails.country.$error, 'has-success': submittedDetails && !$v.formDetails.country.$error }">
                                        <label class="form-label" for="account-country">Land</label>
                                        <input type="text" v-model.lazy="formDetails.country" id="account-country" name="country" class="form-control form-control--light" placeholder="Vul hier uw land in" />
                                        <div v-if="submittedDetails && $v.formDetails.country.$error" class="form-validation">
                                            <span v-if="!$v.formDetails.country.required">Land is verplicht</span>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" class="[ c-btn c-btn--primary ] form-submit-btn">
                                    <svg v-show="!account.status.updating" xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                                        <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                                            <path d="M4.322 10.211h10.995"></path>
                                            <circle cx="10.211" cy="10.211" r="10.211"></circle>
                                            <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                                        </g>
                                    </svg>
                                    <span v-show="account.status.updating"><spinner/></span>
                                    <span v-show="!account.status.updating">Opslaan</span>
                                </button>
                            </form>
                        </tab>
                        <tab title="Wachtwoord">
                            <form v-on:submit.prevent="updatePassword" class="form" novalidate>
                                <div class="form-fieldset">

                                    <div class="form-group" :class="{ 'has-error': submittedPassword && $v.formPassword.password.$error, 'has-success': submittedPassword && !$v.formPassword.password.$error }">
                                        <label class="form-label" for="account-password">Wachtwoord</label>
                                        <input type="password" v-model.lazy="formPassword.password" id="account-password" name="password" class="form-control form-control--light" placeholder="Vul hier uw wachtwoord in" />
                                        <div v-if="submittedPassword && $v.formPassword.password.$error" class="form-validation">
                                            <span v-if="!$v.formPassword.password.required">Wachtwoord is verplicht</span>
                                            <span v-if="!$v.formPassword.password.strongPassword">Wachtwoord moet minstens 6 karakters lang zijn</span>
                                        </div>
                                    </div>

                                    <div class="form-group" :class="{ 'has-error': submittedPassword && $v.formPassword.passwordRepeat.$error, 'has-success': submittedPassword && !$v.formPassword.passwordRepeat.$error }">
                                        <label class="form-label" for="account-password-repeat">Herhaal wachtwoord</label>
                                        <input type="password" v-model.lazy="formPassword.passwordRepeat" id="account-password-repeat" name="password_confirmation" class="form-control form-control--light" placeholder="Herhaal uw wachtwoord" />
                                        <div v-if="submittedPassword && $v.formPassword.passwordRepeat.$error" class="form-validation">
                                            <span v-if="!$v.formPassword.passwordRepeat.required">Herhaal uw wachtwoord</span>
                                            <span v-else-if="!$v.formPassword.passwordRepeat.sameAsPassword">Wachtwoorden moeten overeen komen</span>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" class="[ c-btn c-btn--primary ] form-submit-btn">
                                    <svg v-show="!account.status.updating" xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                                        <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                                            <path d="M4.322 10.211h10.995"></path>
                                            <circle cx="10.211" cy="10.211" r="10.211"></circle>
                                            <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                                        </g>
                                    </svg>
                                    <span v-show="account.status.updating"><spinner/></span>
                                    <span v-show="!account.status.updating">Opslaan</span>
                                </button>
                            </form>
                        </tab>
                        <tab title="Email">
                            <form v-on:submit.prevent="updateEmail" class="form" novalidate>
                                <div class="form-fieldset">
                                    <div class="form-group" :class="{ 'has-error': submittedEmail && $v.formEmail.email.$error, 'has-success': submittedEmail && !$v.formEmail.email.$error }">
                                        <label class="form-label" for="login-email">Email</label>
                                        <input type="email" v-model="formEmail.email" id="login-email" name="email" class="form-control form-control--light" placeholder="Vul hier uw email in" />
                                        <div v-if="submittedEmail && $v.formEmail.email.$error" class="form-validation">
                                            <span v-if="!$v.formEmail.email.required">Email is verplicht</span>
                                            <span v-if="!$v.formEmail.email.email">Email is ongeldig</span>
                                        </div>
                                    </div>
                                </div>

                                <button type="submit" class="[ c-btn c-btn--primary ] form-submit-btn">
                                    <svg v-show="!account.status.updating" xmlns="http://www.w3.org/2000/svg" width="21.422" height="21.422" viewBox="0 0 21.422 21.422">
                                        <g fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" transform="translate(.5 .5)">
                                            <path d="M4.322 10.211h10.995"></path>
                                            <circle cx="10.211" cy="10.211" r="10.211"></circle>
                                            <path d="M10.21 5.106l5.106 5.106-5.106 5.106"></path>
                                        </g>
                                    </svg>
                                    <span v-show="account.status.updating"><spinner/></span>
                                    <span v-show="!account.status.updating">Opslaan</span>
                                </button>
                            </form>
                        </tab>
                    </tabs>
                </template>
            </template>

            <template v-else>
                <app-intro key="account-deleted">
                    <h1>Uw account is verwijderd</h1>
                </app-intro>
            </template>

        </transition-group>
    </section>
</template>

<script>
    import { mapState } from 'vuex';
    import {
        required,
        email,
        minLength,
        sameAs,
    } from 'vuelidate/lib/validators';
    import { Tabs, Tab } from 'vue-slim-tabs';
    import AppIntro from '../../components/app/AppIntro.vue';
    import AppAccountNav from '../../components/app/AppAccountNav.vue';
    import Spinner from '../../components/Spinner.vue';
    import normalizeFormData from '../../helpers/normalizeFormData';

    export default {
        components: {
            Spinner,
            Tabs,
            Tab,
            AppIntro,
            AppAccountNav,
        },
        data () {
            return {
                loading: true,
                // form flags
                submittedDetails: false,
                submittedPassword: false,
                submittedEmail: false,
                // form data
                formDetails: {
                    firstname: null,
                    lastname: null,
                    company: null,
                    address: null,
                    postcode: null,
                    city: null,
                    phone: null,
                    county: 'Kies uw provincie',
                    country: null,
                },
                formPassword: {
                    password: null,
                    passwordRepeat: null,
                },
                formEmail: {
                    email: null,
                },
            };
        },
        computed: {
            ...mapState({
                account: (state) => state.account,
            }),
        },
        validations: {
            formDetails: {
                firstname: { required, minLength: minLength(2) },
                lastname: { required, minLength: minLength(3) },
                company: { minLength: minLength(2) },
                address: { required, minLength: minLength(3) },
                postcode: { required, minLength: minLength(6) },
                city: { required, minLength: minLength(2) },
                phone: { required, minLength: minLength(6) },
                county: { required },
                country: { required, minLength: minLength(3) },
            },
            formPassword: {
                password: {
                    required,
                    strongPassword (password) {
                        return (
                            /[a-z]/.test(password) // checks for a-z
                            && /[0-9]/.test(password) // checks for 0-9
                            && password.length >= 6
                        );
                    },
                },
                passwordRepeat: { required, sameAsPassword: sameAs('password') },
            },
            formEmail: {
                email: { required, email },
            },
        },
        async mounted () {
            await this.$store.dispatch('account/user');

            if (this.account.user !== null)
            {
                // details
                this.formDetails.firstname = this.account.user.firstname;
                this.formDetails.lastname = this.account.user.lastname;
                this.formDetails.address = this.account.user.address;
                this.formDetails.postcode = this.account.user.postcode;
                this.formDetails.city = this.account.user.city;
                this.formDetails.phone = this.account.user.phone;
                this.formDetails.company = this.account.user.company;
                this.formDetails.country = this.account.user.country;
                this.formDetails.county = this.account.user.county;

                // email
                this.formEmail.email = this.account.user.email;

                // reset loading
                this.loading = false;
            }
        },
        methods: {
            resetTab () {
                this.$store.dispatch('alert/clear');

                this.submittedDetails = false;
                this.submittedPassword = false;
                this.submittedEmail = false;

                this.$v.$reset();
            },
            async updateEmail (e) {
                this.submittedEmail = true;

                // validate the form
                this.$v.formEmail.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.formEmail.$pending || this.$v.formEmail.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                // update data
                await this.$store.dispatch('account/update', data);
            },
            async updatePassword (e) {
                this.submittedPassword = true;

                // validate the form
                this.$v.formPassword.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.formPassword.$pending || this.$v.formPassword.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                // update data
                await this.$store.dispatch('account/updatePassword', data);
            },
            async updateDetails (e) {
                this.submittedDetails = true;

                // validate the form
                this.$v.formDetails.$touch();

                // if its still pending or an error is returned do not submit
                if (this.$v.formDetails.$pending || this.$v.formDetails.$error) return;

                // normalize form data
                const data = normalizeFormData(e.target);

                // update data
                await this.$store.dispatch('account/update', data);
            },
            deleteAccount () {
                this.$store.dispatch('account/delete');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import "../../assets/scss/_essentials.scss";

    .form {
        grid-gap: rem(40);
        max-width: 85%;
    }

    .form-submit-btn {
        justify-self: start;
    }
</style>
